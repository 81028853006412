<template>
  <div :class="[b(), className]" :style="styleSizeName" ref="main">
    <div :style="styleChartName">
      <avue-select
        v-if="isSelect"
        :dic="typeof dataChart === 'string' ? [] : dataChart"
        :style="styleSelectName"
        v-model="active"
      ></avue-select>
      <div :class="b('list')" v-else>
        <div
          :class="b('item')"
          :style="[styleName, styleActiveName(item)]"
          v-for="(item, index) in dataChart"
          :key="index"
          @click="handleClick(item, index)"
        >
          <div
            v-if="item.icon"
            :class="b('icon')"
            :style="[
              styleIconName,
              styleIconBgName(item),
              styleIconActiveName(item),
            ]"
          ></div>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "tabs",
  data() {
    return {
      active: "",
      check: null,
      count: 0,
      once: true,
      activeIsOnce: true,
    };
  },
  watch: {
    dataChart: {
      handler(val) {
        //设置默认选中
        this.count = this.com_current;
        if (this.count >= val.length) {
          this.count = 0;
        }
        if (val.length !== 0 && this.once) {
          this.once = false;
          this.handleClick(val[this.count], this.count, true, true);

          //是否开启自动选择下一项
          if (this.scroll) {
            this.autoPlay();
          } else {
            clearInterval(this.check);
          }
        }
      },
      immediate: true,
    },
    active() {
    //判断是否是首次加载页面触发的事件
      if (this.activeIsOnce) {
        this.activeIsOnce = false;
        this.handleClick(
          this.dataChart[parseInt(this.active - 1)],
          parseInt(this.active - 1),
          false,
          true
        );
      } else {
        this.handleClick(
          this.dataChart[parseInt(this.active - 1)],
          parseInt(this.active - 1)
        );
      }
     
    },
    scroll() {
      this.autoPlay();
    },
    time(val) {
      clearInterval(this.check);
      if (val > 0) {
        this.autoPlay();
      }
    },
    com_current: {
      handler(val) {
        this.count = val;
        if (this.count >= this.dataChart.length) {
          this.count = 0;
        };
        this.activeIsOnce = true;
        this.handleClick(this.dataChart[this.count], this.count,false,true);
      },
    },
  },
  computed: {
    com_current() {
      if (this.option.current) {
        return this.option.current;
      } else {
        return 0;
      }
    },
    scroll() {
      return this.option.scroll;
    },
    time() {
      return this.option.time;
    },
    isSelect() {
      return this.type === "select";
    },
    type() {
      return this.option.type;
    },
    paramName() {
      return this.option.paramName;
    },
    iconSize() {
      return this.option.iconSize || 20;
    },
    styleSelectName() {
      return Object.assign(
        {
          fontSize: this.setPx(this.option.fontSize || 30),
        },
        this.styleSizeName
      );
    },
    styleIconName() {
      return Object.assign({
        marginRight: this.setPx(this.option.iconSplit),
        width: this.setPx(this.option.iconSize),
        height: this.setPx(this.option.iconSize),
      });
    },
    styleName() {
      return Object.assign(
        (() => {
          if (this.option.backgroundImage) {
            return {
              backgroundImage: `url(${this.option.backgroundImage})`,
              backgroundSize: "100% 100%",
            };
          }
          return {};
        })(),
        {
          borderColor: this.option.borderColor || "#fff",
          borderStyle: "solid",
          borderWidth: this.setPx(this.option.borderWidth || 0),
          margin: `0 ${this.setPx(this.option.split)}`,
          backgroundColor: this.option.backgroundColor,
          fontSize: this.setPx(this.option.fontSize || 30),
          color: this.option.color,
        }
      );
    },
  },
  methods: {
    styleIconBgName(item) {
      if (item.icon) {
        return {
          backgroundImage: `url(${item.icon})`,
          backgroundSize: "100% 100%",
        };
      }
    },
    styleIconActiveName(item) {
      if (this.active == item.value && item.empIcon) {
        return {
          backgroundImage: `url(${item.empIcon})`,
          backgroundSize: "100% 100%",
        };
      }
    },
    styleActiveName(item) {
      if (this.active == item.value) {
        return Object.assign(
          (() => {
            if (this.option.empBackgroundImage) {
              return {
                backgroundImage: `url(${this.option.empBackgroundImage})`,
                backgroundSize: "100% 100%",
              };
            }
            return {};
          })(),
          {
            borderColor: this.option.empBorderColor || "#fff",
            borderStyle: "solid",
            borderWidth: this.setPx(this.option.empBorderWidth || 0),
            color: this.option.empColor,
          }
        );
      }
    },
    autoPlay() {
      if (this.scroll) {
        clearInterval(this.check);
        this.check = setInterval(() => {
          this.count++;
          if (this.count >= this.dataChart.length) {
            this.count = 0;
          }
          this.handleClick(this.dataChart[this.count], this.count);
        }, this.time);
      } else {
        clearInterval(this.check);
      }
    },
    //isWatch是否是首次加载页面触发的事件
    handleClick(item, index, target = false, isWatch = false) {
      this.active = item.value;
      this.count = index;
      this.updateClick(item);
      this.clickFormatter &&
        this.clickFormatter(
          {
            type: index,
            item: item,
            value: item.value,
            data: this.dataChart,
            isWatch
          },
          this.getItemRefs(),
          isWatch
        );
      if (item.href && !target) window.open(item.href, item.target);
    },
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
});
</script>
