<!-- 自定义配置 -->
<template>
  <div>
    <el-form-item label="圆点颜色">
      <avue-input-color
        v-model="main.activeOption.backgroundColor"
      ></avue-input-color>
    </el-form-item>
      <el-form-item label="宽">
      <avue-input-number
        v-model="main.activeOption.width"
      ></avue-input-number>
    </el-form-item>
      <el-form-item label="高">
      <avue-input-number
        v-model="main.activeOption.height"
      ></avue-input-number>
    </el-form-item>
    <el-form-item label="圆角">
      <avue-input-number
        v-model="main.activeOption.borderRadius"
      ></avue-input-number>
    </el-form-item>
  </div>
</template>

<script>
export default {
  inject: ["main"],
};
</script>

<style>
</style>