<template>
  <div :class="[b(), className]" :style="styleSizeName" ref="main" @click="handleClick">
    <iframe :style="styleChartName" :src="iframeUrl" draggable="false"></iframe>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "iframe",
  data() {
    return {
      iframeUrl: '',
    };
  },
  watch: {
    dataChart: {
      handler(val) {
        let iframeUrl = ''
        if (val.value) {
          iframeUrl = val.value
          let locaLength = window.location.ancestorOrigins.length
          console.log('========>访问地址', window.location.ancestorOrigins)
          if (iframeUrl.indexOf('{location}') != -1) {
            iframeUrl = iframeUrl.replace('{location}', window.location.ancestorOrigins[locaLength - 1])
          }
        }
        console.log('=======地址',iframeUrl,window)
        let searchVal = window.location.search.split('&')[1]
        this.iframeUrl = iframeUrl + '?'+searchVal
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {
    handleClick() {
      this.clickFormatter && this.clickFormatter({
        data: this.dataChart
      }, this.getItemRefs());
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
});
</script>


