<template>
  <div class="mCircular" >
    <div :style="styleName"></div>
  </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "mCircular",
  components: {
    vueSeamlessScroll,
  },
  props: {
    option: Object,
    component: Object,
  },
  computed: {
    borderRadius() {
      return this.option.borderRadius;
    },
    styleName() {
      return Object.assign({
        width: this.option.width + "px",
        height: this.option.height + "px",
        backgroundColor: this.option.backgroundColor,
        borderRadius: this.borderRadius + "px",
      });
    },
  },
  methods: {
   
  },
};
</script>
<style scoped lang="scss">

</style>