<template>
  <div :class="[b(), className]" :style="styleSizeName">
    <el-table
      :style="styleChartName"
      ref="table"
      @cell-click="cellClick"
      :data="dataChart"
      :height="height"
      :border="option.border"
      :cellStyle="cellStyle"
      :row-style="rowStyle"
      :show-header="showHeader"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column
        type="index"
        label="#"
        header-align="center"
        align="center"
        v-if="option.index"
        width="60"
      >
        <span slot-scope="{ $index }">{{ $index + 1 }}</span>
      </el-table-column>
      <template v-for="(item, index) in option.column">
        <el-table-column
          v-if="item.hide !== true"
          show-overflow-tooltip
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <template v-if="isShowSafflower">
              <span v-if="scope.row[item.prop] != 0">{{
                scope.row[item.prop]
              }}</span>
              <img
                v-else
                class=""
                style="width: 30px; height: 30px"
                src="https://rzy-mp.oss-cn-shenzhen.aliyuncs.com/default/public/xiaohonghua.png"
                alt=""
              />
            </template>
            <template v-else>
              <span>{{ scope.row[item.prop] }}</span>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "table",
  data() {
    return {
      headerHeight: "",
      height: "",
      scrollCheck: "",
    };
  },
  watch: {
    dataChart: {
      handler() {
        //动态修改表格头
        if (this.customColumnRun) {
          //根据后端数据自定义修改表头
          this.option.column.forEach((item, index) => {
            item.label = this.dataChart[0].headerList[index].label;
          });
        }
        //数据更新滚动到顶部
        let table = this.$refs.table;
        let divData = table.bodyWrapper;
        divData.scrollTop = 0;
      },
    },
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    },
  },
  computed: {
    scrollLineNum() {
      return this.option.scrollLine;
    },
    customColumnRun() {
      return this.option.customColumn;
    },
    columnTitle() {
      return this.option.column;
    },
    showHeader() {
      return this.option.showHeader;
    },
    scrollTime() {
      return this.option.scrollTime;
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1;
    },
    scroll() {
      return this.option.scroll;
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count);
    },
    isShowSafflower() {
      return this.option.isShowSafflower;
    },
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(
        this.$refs.table.$refs.headerWrapper.clientHeight
      );
      setTimeout(() => {
        this.setTime();
      }, this.scrollTime);
    });
  },
  methods: {
    cellClick(row, column, cell, event) {
      this.updateClick(row);
      this.clickFormatter &&
        this.clickFormatter(
          {
            type: column,
            item: row,
            data: this.dataChart,
          },
          this.getItemRefs()
        );
    },
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(
        this.$refs.table.$refs.headerWrapper.clientHeight
      );
      const table = this.$refs.table;
      const divData = table.bodyWrapper;
      const speed = this.scrollSpeed;
      let top = 0;
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed;
          if (parseInt(this.scrollLineNum) > 0 && this.scrollLineNum) {
            divData.scrollTop = divData.scrollTop + speed * this.scrollLineNum;
          } else {
            divData.scrollTop = divData.scrollTop + speed;
          }

          if (
            divData.clientHeight + divData.scrollTop ==
            divData.scrollHeight
          ) {
            //到最底部滞留一次定时时间
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              divData.scrollTop = 0;
              //回到顶部滞留一次定时时间
              clearInterval(this.scrollCheck);
              setTimeout(() => {
                this.setTime();
              }, this.scrollTime);
            }, this.scrollTime);
          }

          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight);
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime();
            }, this.scrollTime);
          }
        }, 20);
      } else {
        divData.scrollTop = 0;
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign:
          column.type == "index" ? "center" : this.option.bodyTextAlign,
        backgroundColor:
          rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      };
    },
    rowStyle({ rowIndex }) {
      return {
        backgroundColor: "transparent",
      };
    },
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground,
      };
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign:
          column.type == "index" ? "center" : this.option.headerTextAlign,
      };
    },
  },
});
</script>


