<!-- 漏斗图配置 -->
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'funnel',
  inject: ["main"]
}
</script>

<style>
</style>