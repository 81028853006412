<template>
  <div :style="[styleSizeName,styleName]"
       :class="className">
    <div :style="styleChartName">
      <h2>自定义组件</h2><br />
      <h3>我是参数:{{option}}</h3><br />
      <h3>data:{{dataChart}}</h3><br />
      <h3>params:{{(dataAxios.config || {}).params}}</h3><br />
    </div>
  </div>
</template>
<script>
export default {
  name: 'test',
  props: {
    option: Object,
    component: Object
  },
  computed: {
    styleName () {
      return {
        fontSize: this.fontSize,
        color: this.color
      }
    },
    color () {
      return this.option.color || '#fff'
    },
    fontSize () {
      return (this.option.fontSize || 30) + 'px'
    }
  },
  methods: {

  }
}
</script>